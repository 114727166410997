import {useEffect, useState} from 'react';
import {useParams, useOutletContext} from 'react-router-dom';
import {Grid, styled, Typography, Paper, Box} from '@mui/material';

import {getAuditConfigEntries} from 'src/api';
import PageTitle from 'src/components/PageTitle';
import TableWithActionButton from 'src/components/TableWithActionButton';
import SideNavigation from 'src/components/SideNavigation';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import activityLogColumns from './columns';
import SkeletonRows from 'src/components/SkeletonRows';


const GridWrapper = styled(Grid)(
  () => `
    @media screen (max-width: 768px) {
      .MuiDataGrid-cell {
        min-width: 150px !important;
      }
      .MuiDataGrid-columnHeader {
        min-width: 150px !important;
      }
    }
  `
)

const displayAttributes = [
  'sources',
  'categories'
];

const drawerWidth = 400;

function ActivityLog() {
  const {carrierId} = useParams();

  const {carrierConfigResponse} = useOutletContext();
  const {data: carrierConfig, isLoading: carrierConfigLoading, isError: carrierConfigError} = carrierConfigResponse || {};
  const initCompanyName = carrierConfig?.name;

  const [auditEntries, setAuditEntries] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isApiError, setIsApiError] = useState(false);
  const [hideTable, setHideTable] = useState(false)

  useEffect(() => {
    const getAuditEntries = async function () {
      try {
        const result = await getAuditConfigEntries(carrierId, 'matches')
        if (result.status === 400 || result.status === 500 || result.length === 0){
          setHideTable(true);
          setIsLoading(false);
          setIsApiError(true);
        } else {
          setAuditEntries(result);
          setHideTable(false);
          setIsApiError(false);
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
        setIsApiError(true);
      }
    };

    getAuditEntries()
  }, [carrierId]);

  useEffect(() => {
    const dateOptions = {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit',
      timeZoneName: 'short'
    };

    if (auditEntries.length > 0) {
      const filteredEntries = auditEntries.reduce(function(result, item) {
        if(displayAttributes.includes(item?.attributeChanged) || item?.operation === 'INSERT'){
          result.push(item)
        }
        return result;
      }, []);
 
      let arrayMap = filteredEntries.map((item, index) => {
        const container = {};
        container.id = index;
        container.fileName = item?.prefix + '.' + item?.extension;
        container.time = new Date(item?.auditDate).toLocaleDateString('en-US', dateOptions );
        container.sortableTime = item?.auditDate;
        container.sources = item?.sources.length === 0 ? '–' : item?.sources.map((source) => sourceDisplayMap[source]).join(',\n');
        container.categories = item?.categories;
        container.enteredBy = item?.auditUser;
        container.fileNameNickname = item?.nickname;

        return container;
      })      
      setTableData(arrayMap);
    }
  }, [auditEntries]);

  return (
    <>
    <PageTitle title={initCompanyName} isLoading={carrierConfigLoading} isError={carrierConfigError}/>
    <Box sx={{ display: 'flex' }}>
      <SideNavigation drawerWidth={drawerWidth} selected="activityLog" />
      <Box component="main" sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}>

        <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 3 }, fontWeight: 700 }}>
          ACTIVITY LOG (SOURCES & MATCH CATEGORIES ONLY)
        </Typography>

        <GridWrapper>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Paper sx={{ mb: 2, mt: 1 }}>
                <Grid container spacing={2} sx={{ pb: 0 }}>
                  <Grid item xs={12} sx={{mt: -2}}>
                      <TableWithActionButton
                        skeleton={<SkeletonRows widths={['20%', '20%', '20%', '20%', '20%']}/>}
                        isDataTableLoading={isLoading}
                        isError={isApiError}
                        rows={tableData}
                        columns={activityLogColumns}
                        tableHeight={isLoading ? 400 : 775} 
                        useAutoRowHeight={true}
                        hideTable={hideTable}
                        message={'No activity to display.'}
                      />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </GridWrapper>
      </Box>
    </Box>
    </>
  );
}

export default ActivityLog;