import {Fragment} from 'react';
import PropTypes from 'prop-types';
import {times} from 'lodash-es';
import {Divider} from '@mui/material';

import SkeletonRow from './skeletonRow';


function SkeletonRows({numRows=5, widths}) {
  return times(numRows, (row) => <Fragment key={row}><SkeletonRow row={row} widths={widths}/><Divider sx={{opacity: 0.6}}/></Fragment>);
}

SkeletonRows.propTypes = {
  numRows: PropTypes.number,
  widths: PropTypes.array
}

export default SkeletonRows;
