import {useState, useEffect} from 'react';
import {useOutletContext, useSearchParams} from 'react-router-dom';
import {Box, Typography, Alert} from '@mui/material';

import PageTitle from 'src/components/PageTitle';
import SideNavigation from 'src/components/SideNavigation';
import EstimatedClaimsTable from './EstimatedClaimsTable';
import SuccessAlert from './successAlert';


const drawerWidth = 400;

function EstimatedClaims() {
  const {carrierConfigResponse} = useOutletContext();

  const [searchParams] = useSearchParams();

  const [showSuccessAlert, setShowSuccessAlert] = useState(searchParams.get('success') === 'true');
  const [isDownloadError, setIsDownloadError] = useState(false);

  const {data: carrierConfig, isLoading: carrierConfigLoading, isError: carrierConfigError} = carrierConfigResponse || {};
  const companyName = carrierConfig?.name;

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => setShowSuccessAlert(false), 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle title={companyName} isLoading={carrierConfigLoading} isError={carrierConfigError}/>
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="estimatedClaims" />
        <Box component="main" sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}>
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: 3, fontWeight: 700 }}>
            ESTIMATED CLAIMS REPORTS
          </Typography>
          {showSuccessAlert && <Box mt={-1} mb={2}><SuccessAlert/></Box>}
          {isDownloadError && <Alert sx={{ mb: 2, mr: 3 }} onClick={() => setIsDownloadError(false)} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>}
          <EstimatedClaimsTable setIsDownloadError={setIsDownloadError}/>
        </Box>
      </Box>
    </>
  );
}

export default EstimatedClaims;
