export const frequencyValues = [
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'once'
];

export const frequencyLabels = [
  'Daily (Monday-Friday)',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Once'
];

export const frequenciesMap = {
  "daily": "Daily (Monday-Friday)",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "quarterly": "Quarterly",
  "once": "Once"
};

export const toolTipText = "Customer selects from these transfer frequencies when managing their match files in LENS.";
