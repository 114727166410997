import PropTypes from 'prop-types';
import {Field, useFormikContext} from 'formik';
import {Typography, FormControlLabel, FormControl, FormHelperText, Checkbox, Tooltip, Box, Switch} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';

import {toolTipText, frequencyLabels, frequencyValues,} from './constants';


function FrequenciesForm({contractBeginDate, contractEndDate}) {
  const {values, errors, setFieldValue} = useFormikContext();

  return (
    <>
      <Typography variant="subtitle1">Frequencies for this customer account:
        <Tooltip title={toolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
      </Typography>
      <FormHelperText sx={{ mt: 2, ml: -1 }} error={errors.frequencies}>Minimum of one required.{errors.frequencies ? "*" : ''}</FormHelperText>
      <FormControl component="fieldset">
        {frequencyValues?.map((frequencyValue, i) => {
          return <Field as={FormControlLabel} key={frequencyValue} type="checkbox" name='frequencies' label={frequencyLabels[i]} value={frequencyValue} control={<Checkbox />} sx={{ ml: 0 }} />
        })}
      </FormControl>
      {errors.frequencies ?
        <FormHelperText sx={{ mt: 1, ml: -1 }} error={true}> <InfoRounded sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />*One frequency selection is required.</FormHelperText> : ''}
      <Box mt={2} mb={2}>
        <hr/>
      </Box>
      <Tooltip
        arrow
        placement="top"
        title={(!(contractBeginDate && contractEndDate) && "Full DMF Scans Unavailable") || (values?.annualDmf ? "Turn OFF Full DMF Scans" : "Turn ON Full DMF Scans")}
      >
        <span>
          <Switch
            name='annualDmf'
            disabled={Boolean(!contractBeginDate || !contractEndDate)}
            checked={values?.annualDmf}
            onChange={(event) => {setFieldValue('annualDmf', event.target.checked)}} />
        </span>
      </Tooltip>
      <Typography sx={{display: 'inline'}} variant='subtitle1'>Annual Full DMF Scans</Typography>
      <Typography variant='body1'>Annual scans are delivered once per contract year in addition to regular scans.</Typography>
      <Typography variant='body2' color='text.secondary'>NOTE: Account’s contract dates must be provided under Company Profile to turn on full DMF scans.</Typography>
    </>
  );
}

FrequenciesForm.propTypes = {
  contractBeginDate: PropTypes.string,
  contractEndDate: PropTypes.string
};

export default FrequenciesForm;
