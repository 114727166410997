import {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, TableCell, TableRow, IconButton, Collapse} from '@mui/material';
import {ExpandMoreRounded, ExpandLessRounded} from '@mui/icons-material';

import ExpandedContent from './expandedContent';


function ExpandableRow({row, columns, expandable}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell sx={{verticalAlign: 'top', borderBottom: 'unset'}}>
          {expandable &&
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLessRounded /> : <ExpandMoreRounded/>}
            </IconButton>
          }
        </TableCell>
        {columns?.map((column) =>
          <TableCell key={column.field} sx={{verticalAlign: 'top', borderBottom: 'unset'}}>
            {column?.renderCell(row)}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell sx={{ pb: 0, pt: 0 }} colSpan={columns?.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={3} pt={1}>
              <ExpandedContent row={row}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ExpandableRow.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.array,
  expandable: PropTypes.bool
};

export default ExpandableRow;
