import {useFormikContext} from 'formik';
import {Typography, RadioGroup, Radio, FormControlLabel, Box, Tooltip} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';

import {tiersMap, accessText, toolTipText} from './constants';


function TiersForm() {
  const {values, handleChange} = useFormikContext();

  return (
    <>
      <Typography variant="subtitle1">Assign LENS tier for this customer account:
        <Tooltip title={toolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
      </Typography>
      <RadioGroup 
        row 
        id="tiers" 
        name="tiers" 
        value={values?.tiers} 
        onChange={handleChange}
        sx={{ m: 2 }}
      >
        <FormControlLabel value="" control={<Radio/>} label="Standard" />
        <FormControlLabel value="lens-plus" control={<Radio/>} label="Plus" />
        <FormControlLabel value="lens-verified" control={<Radio/>} label="Verified" />
        <FormControlLabel value="lens-plus,lens-verified" control={<Radio/>} label="Plus + Verified" />
      </RadioGroup>
      <Box sx={{ border: '1px solid lightgrey', bgcolor: 'background.default' }} p={3} mt={1}>
        <Typography variant='overline' sx={{ mb: 1, color: 'text.primary' }}>{tiersMap[values?.tiers].label}</Typography>
        <Typography>{tiersMap[values?.tiers].description}</Typography>
        <Typography>{accessText}</Typography>
      </Box>
      <Typography sx={{ mt: 2, mb: -1 }}>When making a tier change, contact the customer to communicate how this may effect their current workflow.</Typography>
    </>
  );
}

export default TiersForm;
