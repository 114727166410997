import {useMemo, useState} from 'react';
import {Grid, CssBaseline} from '@mui/material';
import {QueryClient, QueryClientProvider} from 'react-query';

import ScrollToTop from 'src/components/ScrollToTop';
import AppRoutes from './AppRoutes';
import {AppContext} from './AppContext';
import './App.css';


function App() {  
  const queryClient = new QueryClient();
  const [oktaUser, setOktaUser] = useState(null);

  const provider = useMemo(() => ({
    oktaUser,
    setOktaUser
  }), [oktaUser]);

  return (
    <AppContext.Provider value={provider}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <CssBaseline />
          <Grid>
            <ScrollToTop/>
            <AppRoutes/>  
          </Grid>  
        </div>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
