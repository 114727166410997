export const tiersMap = {
  '': {
    label: 'LENS Standard Tier',
    description: 'Standard customers are responsible for their own customer list and for managing all records in the match results.'
  },
  'lens-plus': {
    label: 'LENS Plus Tier',
    description: 'Customer can send a customer file that doesn’t conform to specifications. The Data team will “clean up” the file and then process the file. The “clean up” will be limited to formatting the data, such as headers, split names into firstName, MiddleName, lastName, format date of birth to YYYYMMDD. The Data team will not enhance/augment the data - i.e. team will not add missing SSN or add missing DOBs. The team may however correct SSNs that appear to have the preceding 0 missing. Automated communication of customer file submission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  },
  'lens-verified': {
    label: 'LENS Verified Tier',
    description: 'The Match file will be verified by the Data team to ensure that the policyholder data matches the death record. Automated communication of match file transmission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  },
  'lens-plus,lens-verified': {
    label: 'LENS Plus + Verified Tier',
    description: 'Customer can send a customer file that doesn’t conform to specifications. The Data team will “clean up” the file and then process the file. The “clean up” will be limited to formatting the data, such as headers, split names into firstName, MiddleName, lastName, format date of birth to YYYYMMDD. The Data team will not enhance/augment the data - i.e. team will not add missing SSN or add missing DOBs. The team may however correct SSNs that appear to have the preceding 0 missing. The Match file will be verified by the Data team to ensure that the policyholder data matches the death record. Automated communication of customer file submission and match file transmission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  }
};

export const accessText = "Tier has access to documentation, file configurations, data loads and match activity.";

export const toolTipText = "LENS tier is based on the customer’s contract with Evadata. Review contract before changing tier.";
