import {Typography, Chip} from '@mui/material';

import FileDownload from './fileDownload';


const basicCellValue = (val) => <div className="wrap-cell top-align" ><Typography variant='body2'>{val || '-'}</Typography></div>;

const statusChipColor = {
  completed: 'success',
  processing: 'info',
  failed: 'error'
}

const columns = (carrierId, setIsDownloadError) => [
  {
    field: 'reportName',
    headerName: 'Report Name',
    renderCell: (row) => basicCellValue(row?.reportName)
  },
  {
    field: 'customerList',
    headerName: 'Customer List',
    renderCell: (row) => {
      if (row?.customerList?.nickName) {
        return (
          <div className="wrap-cell top-align" >
            <Typography variant='body2'>{row?.customerList?.nickName}</Typography>
            <Typography variant='body2'>{row?.customerList?.fileName}</Typography>
          </div>
        )
      }

      return basicCellValue(row?.customerList?.fileName)
    }
  },
  {
    field: 'date',
    headerName: 'Date',
    sortDirection: 'desc',
    renderCell: (row) => basicCellValue(row?.reportCreated)
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (row) => <Chip variant='outlined' size='small' label={row?.status || 'processing'} color={statusChipColor?.[row?.status || 'processing']} sx={{textTransform: 'capitalize'}}/>
  },
  {
    field: 'download',
    headerName: 'Download',
    renderCell: (row) => row?.status === 'completed' && <FileDownload carrierId={carrierId} matchJobId={row?.matchJobId} setIsDownloadError={setIsDownloadError}/>
  }
];

export default columns;
