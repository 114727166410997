import {useOutletContext} from 'react-router-dom';
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {split, last} from 'lodash-es';
import {Grid, Typography, Skeleton, Box} from '@mui/material';
import dayjs from "dayjs";

import {updateDetailsCarrierConfiguration} from 'src/api';
import DisplayContractDates from 'src/components/DisplayContractDates';
import WidgetCard from "../widgetCard";
import ProfileForm from './profileForm';
import BusinessAvatar from './businessAvatar';


function ProfileWidget({carrierId, companyName, website, contractBeginDate, contractEndDate, isDataLoading, isApiError, refetch}) {
  const {accountsResponse} = useOutletContext();

  yup.addMethod(yup.string, 'unique', function (message) {
    return yup.string().test('unique', message, function (_website) {
      let obj = accountsResponse?.data?.find(o => last(split(o.website, '/')) === last(split(_website, '/')) && last(split(_website, '/')) !== last(split(website, '/')));
      return !obj;
    });
  });
  
  const validationSchema = yup.object().shape({
    companyName: yup
      .string()
      .max(25, 'Name exceeds the character limit (maximum is 25 characters')
      .required('This field is required'),
    companyWebsite: yup
      .string()
      .required('This field is required')
      .matches(/^(https:|http:)/, 'Include http:// or https:// at the beginning of the web address.')
      .unique('Website entered already exists. Try a different website.'),
    contractEndDate: yup
      .date()
      .nullable()
      .typeError('Incorrect format entered.')
      .when('contractStartDate', (contractStartDate) => {
        if (yup.date().required().isValidSync(contractStartDate)) {
          return yup.date().typeError('Incorrect format entered.').nullable().min(dayjs(contractStartDate).add(1, 'day').format('MM/DD/YYYY'), 'End date entered must be after the start date.').required('This field is required.');
        }
      }),
    contractStartDate: yup
      .date()
      .nullable()
      .typeError('Incorrect format entered.')
      .when('contractEndDate', (contractEndDate) => { 
        if (yup.date().required().isValidSync(contractEndDate)) {
          return yup.date().typeError('Incorrect format entered.').nullable().required('This field is required.')
        }
      }
  )},['contractEndDate', 'contractStartDate']);

  const formik = useFormik({
    initialValues: {
      companyName: companyName,
      companyWebsite: website,
      contractStartDate: contractBeginDate,
      contractEndDate: contractEndDate
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      formik?.setStatus({error: false});
      try {
        const payload = {
          name: values.companyName,
          website: values.companyWebsite
        }

        if (values.contractStartDate && values.contractEndDate) {
          payload.beginDate = values.contractStartDate;
          payload.endDate = values.contractEndDate;
        }

        const result = await updateDetailsCarrierConfiguration(carrierId, payload);

        if (result?.statusCode === 400 || result?.statusCode === 500) {
          formik?.setStatus({error: true});
        }
        else {
          await refetch();
        }
      } catch (error) {
        formik?.setStatus({error: true});
      }
    }
  });

  return (
    <WidgetCard title='Company Profile' formik={formik} editingForm={<ProfileForm/>} isLoading={isDataLoading} isError={isApiError} errorContent={<BusinessAvatar/>}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <BusinessAvatar/>
          <Typography variant="h5">
            {isDataLoading ? <Skeleton/> : companyName}
          </Typography>
          <Box sx={{my: 3}}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {isDataLoading ? <Skeleton/> : website}
            </Typography>
            <DisplayContractDates beginDate={contractBeginDate} endDate={contractEndDate} isLoading={isDataLoading}/>
          </Box>
        </Grid>
      </Grid>
    </WidgetCard>
  );
}

ProfileWidget.propTypes = {
  carrierId: PropTypes.string,
  companyName: PropTypes.string,
  website: PropTypes.string,
  contractBeginDate: PropTypes.string,
  contractEndDate: PropTypes.string,
  isDataLoading: PropTypes.bool,
  isApiError: PropTypes.bool,
  refetch: PropTypes.func
};

export default ProfileWidget;
