import {Typography, styled, Button, Box} from '@mui/material';


const ErrorWrapper = styled(Box)(
  () => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  `
);

function LoadError(props) {

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <ErrorWrapper {...props}>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>We’re having trouble loading the content, please try again.</Typography>
      <Button size="small" variant="contained" color="primary" onClick={refreshPage}>Retry</Button>
    </ErrorWrapper>
  );
}

export default LoadError;