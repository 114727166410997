import {useState} from 'react';
import PropTypes from 'prop-types';
import {FormikProvider} from 'formik';
import {Grid, Paper, Typography, Button, Alert, Box} from '@mui/material';
import {EditRounded} from '@mui/icons-material';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import LoadError from 'src/components/LoadError';


function Loading() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ minHeight: '300px' }}>
          <LoadingIcon contained={true}/>
        </Box>
      </Grid>
    </Grid>
  );
}

function WidgetCard({title, formik, editingForm, isLoading, isError, errorContent, children}) {
  const [editing, setEditing] = useState(false);

  const readOnlyContent = isError ? <>{errorContent}<LoadError/></> : children;

  const cancelEdit = () => {
    setEditing(false);
    formik?.setStatus({error: false});
    formik?.setErrors({});
  }

  const edit = () => {
    setEditing(true);
    formik?.setStatus({});
    formik?.resetForm();
  }

  const submit = (event) => {
    event.preventDefault();
    formik?.submitForm();
    setEditing(false);
  }

  return (
    <Paper>
      {formik?.isSubmitting ? <Loading /> :
        <>
          <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1 }}>
            <Grid item xs={8}>
              <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              {editingForm &&
                (editing || formik?.status?.error || !formik?.isValid ?
                  <Button onClick={cancelEdit} sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>
                  :
                  <Button onClick={edit} startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined" disabled={isLoading || isError}>Edit</Button>
                )
              }         
            </Grid>
          </Grid>
          <hr/>
          <FormikProvider value={formik}>
            <form onSubmit={formik?.handleSubmit}>
              <Grid container spacing={3} sx={{ p: 4, pt: 3 }}>
                <Grid item xs={12}>
                  {formik && (editing || formik?.status?.error || !formik?.isValid) ?
                    <>
                      {formik?.status?.error && <Alert sx={{mb: 2}} severity="error">We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
                      {editingForm}
                    </>
                    :
                    readOnlyContent
                  }
                </Grid>
              </Grid>
              {formik && (editing || formik?.status?.error || !formik?.isValid) &&
                <>
                  <hr/>
                  <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                    <Button type="submit" onClick={submit} variant="contained">
                      Save Changes
                    </Button>
                  </Box>
                </>
              }
            </form>
          </FormikProvider>
        </>
      }
    </Paper>
  );
}

WidgetCard.propTypes = {
  title: PropTypes.string,
  formik: PropTypes.object,
  editingForm: PropTypes.node,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorContent: PropTypes.node,
  children: PropTypes.node
}

export default WidgetCard;