import {useState} from 'react';
import PropTypes from 'prop-types';
import {times} from 'lodash-es';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton, Typography, Grid, styled, TablePagination} from '@mui/material';

import LoadError from 'src/components/LoadError';
import CollapsibleRow from './collapsibleRow';


const GridWrapper = styled(Grid)(
  ({ theme }) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }
    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
}
.MuiDataGrid-selectedRowCount {
  visibility: hidden;
}
.MuiTablePagination-selectLabel, .MuiTablePagination-select, svg.MuiSvgIcon-root.MuiTablePagination-selectIcon {
  visibility: hidden;
}
.page-count {
  font-size: 15px;
  bottom: 15px;
  position: absolute;
  right: 100px;
}
@media screen and (max-width: 599px) {
  .MuiDataGrid-virtualScroller {
    overflow-x: scroll !important;
  }
}
  `
)

function CollapsibleTable({ data, columns, message, usePagination = false, isLoading = false, isError = false, openCalendarDrawer, setHolidayCalendarId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const currentRows = data?.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
    {!isLoading && !isError && (!data || data?.length === 0) ?
      <GridWrapper container sx={{ pr: 0, pb: 0 }}>
        <Grid item xs={12}>
          <Paper sx={{ mb: 0, mt: 1 }}>
            <Grid sx={{height:90}}>
              <Typography sx={{ p: 3 }}>{message}</Typography>
            </Grid>
          </Paper>
        </Grid>
      </GridWrapper>
      :
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              {columns?.map((column) => (<TableCell key={column?.field}>{column?.headerName}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: 500 }}>
            {isError &&
              <TableRow>
                <TableCell colSpan={columns?.length + 1}>
                  <LoadError height='400px'/>
                </TableCell>
              </TableRow>
            }
            {isLoading &&
              times(5, () => (
                <TableRow>
                  <TableCell><Skeleton/></TableCell>
                  {columns?.map((column) => (
                    <TableCell key={column?.field}><Skeleton /></TableCell>
                  ))}
                </TableRow>
              ))
            }
            {usePagination ?
              currentRows?.map((row) => (
                <CollapsibleRow key={row?.customerList || row?.id} row={row} columns={columns} openCalendarDrawer={openCalendarDrawer} setHolidayCalendarId={setHolidayCalendarId}/>
              )) :
              data?.map((row) => (
                <CollapsibleRow key={row?.customerList || row?.id} row={row} columns={columns} openCalendarDrawer={openCalendarDrawer} setHolidayCalendarId={setHolidayCalendarId}/>
              ))
            }
          </TableBody>
        </Table>
        {usePagination &&
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </TableContainer>
    }
  </>
  );
}

CollapsibleTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  message: PropTypes.string,
  usePagination: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  setHolidayCalendarId: PropTypes.func,
  openCalendarDrawer: PropTypes.bool
}

export default CollapsibleTable;