import {Field, useFormikContext} from 'formik';
import {Typography, Box, FormControl, Checkbox, FormControlLabel, FormHelperText, Tooltip} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';

import {toolTipText} from './constants';


function SourcesForm() {
  const {values, errors} = useFormikContext();

  return (
    <>
      <Typography variant='subtitle1' color='text.primary'>
        Sources for this customer account:
        <Tooltip title={toolTipText} arrow placement='right' sx={{ml: '10px', mb: '-3px'}}>
          <InfoRounded color='primary' fontSize='small'/>
        </Tooltip>
      </Typography>
      <FormHelperText sx={{ mb: 0, mt: 1, ml: -1 }} error={errors.sources}>Minimum of one source required.*</FormHelperText>
      <Box sx={{ml: -1}} role="optgroup" aria-labelledby="checkbox-group">
        <FormControl component="fieldset">
          <Field as={FormControlLabel} type="checkbox" name='sources' label='Carrier-to-Carrier' value='carrier' control={<Checkbox />} sx={{ ml: 1 }} />
          <Field as={FormControlLabel} type="checkbox" name='sources' label='DMF' value='dmf' control={<Checkbox />} sx={{ ml: 1 }} />
          <Field as={FormControlLabel} type="checkbox" name='sources' label='Obit Funeral Home' value='funeral-home-obituary' control={<Checkbox />} sx={{ ml: 1 }} />
          <Field as={FormControlLabel} type="checkbox" name='sources' label='Obit Newspaper' value='newspaper-obituary' control={<Checkbox />} sx={{ ml: 1 }} />
          <Field as={FormControlLabel} type="checkbox" name='sources' label='State' value='state' control={<Checkbox />} sx={{ ml: 1 }} />
          {values?.sources?.length === 0 ? <FormHelperText sx={{ mb: 4, mt: 1 }} error={true}> <InfoRounded sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />*One source selection is required.</FormHelperText> : ''}
        </FormControl>
      </Box>
    </>
  );
}

export default SourcesForm;
