import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Typography, Tooltip, Box, Skeleton} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';
import {without, concat, intersection} from 'lodash-es';

import {updateTransferFrequencies} from 'src/api';
import WidgetCard from '../widgetCard';
import FrequenciesForm from './frequenciesForm';
import {frequencyValues, frequenciesMap, toolTipText} from './constants';


const validationSchema = yup.object().shape({
  frequencies: yup
    .array()
    .min(1, 'Field is required'),
  annualDmf: yup.bool()
})

function FrequencyWidget({carrierId, scanFrequencies, contractBeginDate, contractEndDate, isDataLoading, isApiError, refetch}) {
  const frequencies = without(scanFrequencies, 'dmf-annual');
  const hasAnnualDmf = scanFrequencies?.includes('dmf-annual');
  const dmfStatus = hasAnnualDmf ? 'Enabled' : 'Disabled';

  const formik = useFormik({
    initialValues: {
      frequencies: frequencies,
      annualDmf: hasAnnualDmf
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      formik?.setStatus({error: false});
      try {
        const scanFrequencies = concat(without(values.frequencies, 'dmf-annual'), values.annualDmf ? 'dmf-annual' : []);
        const payload = { scanFrequencies };
        const result = await updateTransferFrequencies(carrierId, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          formik?.setStatus({error: true});
        }
        else {
          await refetch();
        }
      } catch (error) {
        formik?.setStatus({error: true});
      }
    }
  });

  return (
    <WidgetCard title='Match Results Transfer Frequencies' formik={formik} editingForm={<FrequenciesForm contractBeginDate={contractBeginDate} contractEndDate={contractEndDate}/>} isLoading={isDataLoading} isError={isApiError}>
      <Typography variant="subtitle1">
        Frequencies for this customer account:
        <Tooltip title={toolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
      </Typography>
      {isDataLoading ? <Skeleton/> :
        (intersection(frequencyValues, scanFrequencies)
          .map((item) => (
            <Typography key={item} variant='body1'>{frequenciesMap[item]}</Typography>
          ))
        )
      }
      <Box mt={2} mb={2}>
        <hr/>
      </Box>
      <Typography><strong>Annual Full DMF Scans:</strong> {isDataLoading ? <Skeleton width='185px' sx={{display: 'inline-flex'}}/> : dmfStatus}</Typography>
      <Typography>Annual scans are delivered once per contract year in addition to regular scans.</Typography>
    </WidgetCard>
  );
}

FrequencyWidget.propTypes = {
  carrierId: PropTypes.string,
  scanFrequencies: PropTypes.array,
  contractBeginDate: PropTypes.string,
  contractEndDate: PropTypes.string,
  isDataLoading: PropTypes.bool,
  isApiError: PropTypes.bool,
  refetch: PropTypes.func
}

export default FrequencyWidget;
