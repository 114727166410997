import PropTypes from 'prop-types';
import {Skeleton, Box} from '@mui/material';


function SkeletonRow({widths, row}) {
  return (
    <Box display='flex'>
      {widths.map((width, col) => <Skeleton key={`${row}-${col}`} sx={{ml: 1, mr: 1, mt: 2, mb: 2}} width={width}/>)}
    </Box>
  );
}

SkeletonRow.propTypes = {
  widths: PropTypes.array,
  row: PropTypes.number
}

export default SkeletonRow;
