import {Outlet} from 'react-router-dom';
import {useQuery} from 'react-query';

import {getAllCarrierConfigurations} from 'src/api';


function AccountsContext() {
  const accountsResponse = useQuery(['allCarrierConfigurations'], () => getAllCarrierConfigurations(), {retry: false});

  return (<Outlet context={{accountsResponse}}/>);
}

export default AccountsContext;
