import {useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import {Link, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {map, filter, includes} from 'lodash-es';
import {useFormik, FormikProvider, Field} from 'formik';
import * as yup from 'yup';
import {Box, Button, Grid, Paper, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormHelperText, Alert, Skeleton} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';

import {getCustomerFileConfigurations, getMatchFileConfigurations, initiatePrescan} from 'src/api';
import PageTitle from 'src/components/PageTitle';
import SelectCategories from 'src/components/SelectCategories'
import LoadingIcon from 'src/components/Loading/loadingIcon';
import ErrorAlert from 'src/components/ErrorAlert';
import SideNavigation from 'src/components/SideNavigation';
import SelectSources from 'src/components/SelectSources';


const drawerWidth = 400;

function RequestReport() {
  const {carrierId} = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient()

  const [custFileConfigs, setCustFileConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [resetSources, setResetSources] = useState(false);

  const {carrierConfigResponse} = useOutletContext();
  const {data: carrierConfig, isLoading: carrierConfigLoading, isError: carrierConfigError} = carrierConfigResponse || {};
  const companyName = carrierConfig?.name;

  useEffect(() => {
    const getCustFileConfigs = async function () {
      return getCustomerFileConfigurations(carrierId);
    };

    const getMatchFileConfigs = async function () {
      return getMatchFileConfigurations(carrierId);
    };

    Promise.all([
      getCustFileConfigs(),
      getMatchFileConfigs()
    ]).then((configs) => {
      const activeCustFilePrefixes = map(configs[1], 'customerFilePrefix');
      const _custFileConfigs = filter(configs[0], (config) => includes(activeCustFilePrefixes, config.file.prefix));
      setCustFileConfigs(_custFileConfigs);
      setLoading(false);
    });
  }, [carrierId]);

  const validationSchema = yup.object().shape({
    customerFilePrefix: yup
      .string()
      .required(),
    sources: yup
      .array()
      .min(1),
    categories: yup
      .array()
      .min(1)
  });

  const formik = useFormik({
    initialValues: {
      customerFilePrefix: "",
      sources: [],
      categories: []
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (formValues, { resetForm }) => {
      setApiError(false);
      setSubmitLoading(true);
      try {
        const result = await initiatePrescan(carrierId, formValues)

        if (result.statusCode === 400 || result.statusCode === 500) {
          setApiError(true);
          window.scrollTo(0, 0);
        }
        if (result.success) {
          await queryClient.refetchQueries({ queryKey: ['reports'], type: 'active' })
          navigate('../estimated-claims?success=true')
        } else {
          setApiError(true);
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.log(error)
        setApiError(true);
      }
      setSubmitLoading(false);
    },
  });

  function updateSelectedCategories(allSelected) {
    formik.setFieldValue("categories", allSelected);
  }

  const validate = () => {
    if (formik.errors) {
      setFormTouched(true);
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
    else {
      setShowErrorAlert(false);
    }
  }

  function errorMessageVisible(formikValue) {
    return Boolean(formik.touched[formikValue] && formik.errors[formikValue]);
  }

  return (
    <>
      {submitLoading && <LoadingIcon />}
      <FormikProvider value={formik}>
        <ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />
        <form onSubmit={formik.handleSubmit}>
          <PageTitle title={companyName} isLoading={carrierConfigLoading} isError={carrierConfigError}/>
          <Box sx={{ display: 'flex' }}>
            <SideNavigation drawerWidth={drawerWidth} selected="estimatedClaims" />
            <Box
              component="main"
              sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
            >
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: 1, fontWeight: 700 }}>
                    REQUEST A CLAIMS REPORT
                  </Typography>
                </Grid>
                <Grid item>
                  <Button LinkComponent={Link} to='../estimated-claims' variant="text" color="primary" sx={{mb: 1}}><ArrowBack sx={{ mr: 2 }} />Back to All Reports</Button>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} mb={1}>
                  {apiError && <Alert severity="error" onClick={() => setApiError(false)}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Paper>
                    <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="text.primary" fontWeight={700}>Request Form</Typography>
                        <Typography>Submissions will start processing immediately, with generated results available same day.</Typography>
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={3} sx={{ p: 3, pl: 4 }}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" color="text.secondary" name="customerFilePrefix">LIST CONFIGURATION</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ pb: 1 }}>Choose which customer list to run the report against:</Typography>
                        <FormControl sx={{ pb: 3 }}>
                          <FormHelperText error={errorMessageVisible('customerFilePrefix')}>
                            <Typography variant="body2" ml={-2}>Minimum of one list required.{errorMessageVisible('customerFilePrefix') && '*'}</Typography>
                          </FormHelperText>
                          <RadioGroup>
                            {loading ? 
                            <Box sx={{display: 'inline-flex'}}>
                              <Radio checked={false} disabled={true}/>
                              <Skeleton width='100%'/>
                            </Box>
                            :
                          custFileConfigs.map((config) => {
                              const fileName = `${config.file.prefix}.${config.file.type}`;
                              const label = config.nickname ? `${config.nickname} (${fileName})` : fileName;
                              return <Field sx={{ pl: 1 }} as={FormControlLabel} name="customerFilePrefix" key={config.file.prefix} value={config.file.prefix} control={<Radio />} checked={config.file.prefix === formik?.values?.customerFilePrefix} label={label} />
                            })}
                          </RadioGroup>
                          <FormHelperText error={errorMessageVisible('customerFilePrefix')}>
                            <Typography fontSize="13px" ml={-2}>{errorMessageVisible('customerFilePrefix') ? '*At least one list selection is required.' : 'Shown are only actively running customer lists.'}</Typography>
                          </FormHelperText>
                        </FormControl>
                        <hr />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" color="text.secondary" name="sources">SOURCES</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ pb: 1 }}>Sources customer records should be matched against in the report:</Typography>
                        <SelectSources formik={formik} />
                        <hr />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" color="text.secondary" sx={{ pb: 3 }} name="categories">MATCH CATEGORIES</Typography>
                        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1 }}>Selected categories to appear in the report:</Typography>
                        <SelectCategories updateSelectedCategories={updateSelectedCategories} formTouchedProp={formTouched} existingCategories={[]} initialized={resetSources} setInitialized={setResetSources} />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container sx={{ p: 2, pb: 3 }} justifyContent='space-between'>
                      <Grid item>
                        <Button LinkComponent={Link} to='../estimated-claims' variant='outlined'>Cancel</Button>
                      </Grid>
                      <Grid item>
                        <Button type="submit" onClick={validate} variant="contained" disabled={loading}>Submit Request</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </FormikProvider>
    </>
  );
}

export default RequestReport;