import {Table, TableHead, Box, TableCell, Skeleton} from "@mui/material";


function SkeletonTable() {
  return (
    <Table sx={{border: '1px solid rgba(0, 0, 0, 0.23)'}}>
      <TableHead sx={{backgroundColor: 'background.default', height: '56px'}}>
        <Box display='inline-flex' alignItems='center' margin={2}>
        <Skeleton height='30px' width='450px'/>
        <Skeleton height='16px' width='50px' sx={{ml: 3}}/>
        </Box>
      </TableHead>
      <TableCell sx={{borderBottom: 'unset', borderTop: '1px solid rgba(0, 0, 0, 0.23)'}}>
        <Skeleton height='16px'/>
      </TableCell>
    </Table>
  );
}

export default SkeletonTable;