import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, FormControlLabel, Checkbox, FormControl, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Grid, FormHelperText } from '@mui/material';
import * as yup from 'yup';
import { Field, useFormik, FormikProvider } from 'formik';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
  selectedCategories: yup
    .array()
    .min(1, 'Field is required')
})

function NestedChecklist({ primaryLabel, cat, SelectCategory, mockCatObj, formik, handleClick, openCats }) {
  return (
    <>
      <ListItemButton sx={{ width: '100%' }}>
        <ListItemIcon>
          <Checkbox sx={{ width: '10px' }} onClick={() => SelectCategory(cat, mockCatObj[cat]?.length, mockCatObj[cat])}
            edge="start"
            checked={formik.values[cat]?.length === mockCatObj[cat]?.length}
            indeterminate={(formik.values[cat]?.length > 0) && (formik.values[cat]?.length < mockCatObj[cat]?.length)}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText sx={{ width: '10px' }} id={cat} primary={primaryLabel} />
        {openCats[cat] ? <ExpandLess onClick={() => handleClick(cat)} /> : <ExpandMore onClick={() => handleClick(cat)} />}
      </ListItemButton>
      <Collapse in={openCats[cat]} timeout="auto" unmountOnExit>
        <FormControl component="fieldset">
          {mockCatObj[cat].map((category) => (
            <Field key={category} as={FormControlLabel} type="checkbox" name={cat} checked={formik?.values?.[cat].includes(category)} label={category} value={category} control={<Checkbox />} sx={{ ml: 1 }} />
          ))}
        </FormControl>
      </Collapse>
    </>
  )
}

NestedChecklist.propTypes = {
  primaryLabel: PropTypes.string, 
  cat: PropTypes.string, 
  SelectCategory: PropTypes.func, 
  mockCatObj: PropTypes.object, 
  formik: PropTypes.object, 
  handleClick: PropTypes.func, 
  openCats: PropTypes.object
}

function SelectCategories({ updateSelectedCategories, existingCategories, formTouchedProp, initialized, setInitialized }) {
  const navigate = useNavigate();
  const [formTouched, setFormTouched] = useState(false);
  const mockCatObj =
  {
    'cat1': ['1.1'],
    'cat2': ['2.1', '2.2'],
    'cat3': ['3.1'],
    'cat4': ['4.1'],
    'cat5': ['5.1', '5.2'],
    'cat6': ['6.1'],
    'cat7': ['7.1'],
    'cat8': ['8.1', '8.2'],
    'cat9': ['9.1'],
    'cat10': ['10.1', '10.2', '10.3'],
    'cat11': ['11.1', '11.2', '11.3', '11.4', '11.5', '11.6'],
    'cat12': ['12.1', '12.2', '12.3'],
    'cat13': ['13.1', '13.2', '13.3'],
    'cat14': ['14.1', '14.2', '14.3', '14.4', '14.5', '14.6'],
    'cat15': ['15.1', '15.2', '15.3', '15.4', '15.5', '15.6'],
    'cat16': ['16.1', '16.2', '16.3', '16.4'],
    'cat17': ['17.1', '17.2', '17.3', '17.4', '17.5', '17.6', '17.7', '17.8'],
    'cat18': ['18.1', '18.2', '18.3', '18.4'],
    'cat19': ['19.1', '19.2',],
    'cat20': ['20.1', '20.2', '20.3', '20.4'],
    'cat21': ['21.1', '21.2', '21.3'],
    'cat22': ['22.1', '22.2', '22.3', '22.4'],
    'cat23': ['23.1', '23.2',],
    'cat24': ['24.1', '24.2', '24.3', '24.4'],
    'cat25': ['25.1'],
    'cat26': ['26.1', '26.2',],
  }

  const [openCats, setOpenCats] = useState({})

  const handleClick = (myCat) => {
    let openState = openCats[myCat];
    setOpenCats({
      ...openCats,
      [myCat]: !openState
    });
    if (!formTouched) {
      setFormTouched(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedCategories: [],
      cat1: [],
      cat2: [],
      cat3: [],
      cat4: [],
      cat5: [],
      cat6: [],
      cat7: [],
      cat8: [],
      cat9: [],
      cat10: [],
      cat11: [],
      cat12: [],
      cat13: [],
      cat14: [],
      cat15: [],
      cat16: [],
      cat17: [],
      cat18: [],
      cat19: [],
      cat20: [],
      cat21: [],
      cat22: [],
      cat23: [],
      cat24: [],
      cat25: [],
      cat26: [],
    }, validationSchema: validationSchema
  });

  const updateAll = useCallback(() => {
    let allSelected = [...formik.values.cat1, ...formik.values.cat2, ...formik.values.cat3, ...formik.values.cat4, ...formik.values.cat5, ...formik.values.cat6, ...formik.values.cat7, ...formik.values.cat8, ...formik.values.cat9, ...formik.values.cat10, ...formik.values.cat11, ...formik.values.cat12, ...formik.values.cat13, ...formik.values.cat14, ...formik.values.cat15, ...formik.values.cat16, ...formik.values.cat17, ...formik.values.cat18, ...formik.values.cat19, ...formik.values.cat20, ...formik.values.cat21, ...formik.values.cat22, ...formik.values.cat23, ...formik.values.cat24, ...formik.values.cat25, ...formik.values.cat26];
    formik.setFieldValue("selectedCategories", allSelected);
    updateSelectedCategories(allSelected);
  }, [formik, updateSelectedCategories]);

  function SelectCategory(cat, length, subcats) {
    if (formik.values[cat]?.length === length) {
      formik.setFieldValue(cat, []);
    } else {
      formik.setFieldValue(cat, subcats);
    }
    if (!formTouched) {
      setFormTouched(true);
    }
  }

  function selectAll() {
    if (formik.values.selectedCategories?.length === 0) {
      for (let cat in mockCatObj) {
        formik.setFieldValue(cat, mockCatObj[cat]);
      }
    } else {
      for (let cat in mockCatObj) {
        formik.setFieldValue(cat, []);
      }
    }
    if (!formTouched) {
      setFormTouched(true);
    }
  }

  useEffect(() => {
    setFormTouched(formTouchedProp);
  }, [formTouchedProp])

  useEffect(updateAll, [formik.values.cat1, formik.values.cat2, formik.values.cat1, formik.values.cat2, formik.values.cat3, formik.values.cat4, formik.values.cat5, formik.values.cat6, formik.values.cat7, formik.values.cat8, formik.values.cat9, formik.values.cat10, formik.values.cat11, formik.values.cat12, formik.values.cat13, formik.values.cat14, formik.values.cat15, formik.values.cat16, formik.values.cat17, formik.values.cat18, formik.values.cat19, formik.values.cat20, formik.values.cat21, formik.values.cat22, formik.values.cat23, formik.values.cat24, formik.values.cat25, formik.values.cat26]);

  useEffect(() => {
    const setValue = async function () {
      let catObject = {
        cat1: [], cat2: [], cat3: [], cat4: [], cat5: [], cat6: [], cat7: [], cat8: [], cat9: [], cat10: [], cat11: [], cat12: [], cat13: [], cat14: [], cat15: [], cat16: [], cat17: [], cat18: [], cat19: [], cat20: [], cat21: [], cat22: [], cat23: [], cat24: [], cat25: [], cat26: [],
      }
      if (existingCategories) {
        for (let cat in existingCategories) {
          const selectedCat = existingCategories[cat];
          const catString = existingCategories[cat].split('.');
          const catParent = 'cat' + catString[0];
          catObject[catParent].push(selectedCat);
        }
        await formik.setValues(catObject);
      }
      setInitialized(true);
    }
    if (!initialized && existingCategories) {
      setValue().catch((error) => {
        navigate('/500?page=' + window.location.pathname.replace(/^\/+/g, '') + '&error=' + error);
      });
    }
  }, [formik, setInitialized, existingCategories, initialized, navigate]);

  return (
    <FormikProvider value={formik}>
        <Button name="newMatchSelectedCategories" sx={{ minWidth: '130px' }} variant="text" onClick={selectAll}>{formik?.values?.selectedCategories?.length > 0 ? 'Select None' : 'Select All'}</Button> {formik?.values?.selectedCategories?.length} categories selected
        <FormHelperText error={(formik.errors.selectedCategories && (formTouched === true))}>
          <Typography variant="body2" mt={1} ml={-2}>Minimum of one category required.{(formik.errors.selectedCategories && (formTouched === true)) && '*'}</Typography>
        </FormHelperText>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', pl: 2 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Grid container>
            <Grid item xs={3} sx={{ borderRight: '2px solid #ccc' }}>
              <Typography variant="subtitle1" color="text.secondary">SSN Match</Typography>
              <NestedChecklist primaryLabel="1" cat='cat1' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="2" cat='cat2' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="3" cat='cat3' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="4" cat='cat4' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="5" cat='cat5' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="6" cat='cat6' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="7" cat='cat7' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="8" cat='cat8' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="9" cat='cat9' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
            </Grid>
            <Grid item xs={3} sx={{ pl: 2, borderRight: '2px solid #ccc' }}>
              <Typography variant="subtitle1" color="text.secondary">SSN Fuzzy</Typography>
              <NestedChecklist primaryLabel="10" cat='cat10' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="11" cat='cat11' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="12" cat='cat12' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="13" cat='cat13' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="14" cat='cat14' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
            </Grid>
            <Grid item xs={3} sx={{ pl: 2, borderRight: '2px solid #ccc' }}>
              <Typography variant="subtitle1" color="text.secondary">SSN Null/Invalid</Typography>
              <NestedChecklist primaryLabel="15" cat='cat15' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="16" cat='cat16' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="17" cat='cat17' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="18" cat='cat18' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="19" cat='cat19' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="20" cat='cat20' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
            </Grid>
            <Grid item xs={3} sx={{ pl: 2 }}>
              <Typography variant="subtitle1" color="text.secondary">SSN No Match</Typography>
              <NestedChecklist primaryLabel="21" cat='cat21' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="22" cat='cat22' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="23" cat='cat23' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="24" cat='cat24' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="25" cat='cat25' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
              <NestedChecklist primaryLabel="26" cat='cat26' SelectCategory={SelectCategory} mockCatObj={mockCatObj} formik={formik} handleClick={handleClick} openCats={openCats} />
            </Grid>
          </Grid>
        </List>
        <FormHelperText className={(formik.errors.selectedCategories && (formTouched === true)) ? 'show' : 'hide'} error={true}>
          <Typography fontSize="13px" ml={-2}>*One category selection is required.</Typography>
        </FormHelperText>
    </FormikProvider>
  );
}

SelectCategories.propTypes = {
  updateSelectedCategories: PropTypes.func,
  existingCategories: PropTypes.array,
  formTouchedProp: PropTypes.bool,
  initialized: PropTypes.bool,
  setInitialized: PropTypes.func
};

export default SelectCategories;
