import { BusinessRounded } from '@mui/icons-material';
import { Alert, Avatar, Button, FormHelperText, Grid, Paper, styled, TextField, Typography, RadioGroup, Radio, FormControlLabel, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import {last, split, omitBy, isEmpty, map} from 'lodash-es';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle';
import { createCarrierConfiguration, getAllCarrierConfigurations } from 'src/api';
import LoadingIcon from 'src/components/Loading/loadingIcon';

const steps = [
  {
    label: 'Prospect (customer starts in this stage)',
    description: 'Prospective customers actively involved in contract review and need access to see documentation.',
  },
  {
    label: 'Onboarding',
    description:
      'Customers who have signed contracts and are ready to access the self-onboarding steps.',
  },
  {
    label: 'Live',
    description: 'Customers who completed onboarding steps and are ready to move live to start receiving matches.',
  },
];

const tiers = {
  'standard': {
    label: 'LENS STANDARD TIER',
    description: 'Standard customers are responsible for their own customer list and for managing all records in the match results.'
  },
  'lens-plus': {
    label: 'LENS PLUS TIER',
    description: 'Customer can send a customer file that doesn’t conform to specifications. The Data team will “clean up” the file and then process the file. The “clean up” will be limited to formatting the data, such as headers, split names into firstName, MiddleName, lastName, format date of birth to YYYYMMDD. The Data team will not enhance/augment the data - i.e. team will not add missing SSN or add missing DOBs. The team may however correct SSNs that appear to have the preceding 0 missing. Automated communication of customer file submission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  },
  'lens-verified': {
    label: 'LENS VERIFIED TIER',
    description: 'The Match file will be verified by the Data team to ensure that the policyholder data matches the death record. Automated communication of match file transmission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  },
  'lens-plus,lens-verified': {
    label: 'LENS PLUS + VERIFIED TIER',
    description: 'Customer can send a customer file that doesn’t conform to specifications. The Data team will “clean up” the file and then process the file. The “clean up” will be limited to formatting the data, such as headers, split names into firstName, MiddleName, lastName, format date of birth to YYYYMMDD. The Data team will not enhance/augment the data - i.e. team will not add missing SSN or add missing DOBs. The team may however correct SSNs that appear to have the preceding 0 missing. The Match file will be verified by the Data team to ensure that the policyholder data matches the death record. Automated communication of customer file submission and match file transmission will be suppressed. Customer will be sent personalized emails by an Evadata team member.'
  }
}

const accessText = "Tier has access to documentation, file configurations, data loads and match activity.";

const PaperWrapper = styled(Paper)(
  ({ theme }) => `
  &.loading-wrapper {
    min-height: 600px;
  }
    .company-icon {
      color: ${theme.palette.action.active};
      font-size: 30px;
    }

    .info-icon {
      position: absolute;
      margin-left: 4px;
    }

    .stepper-1 .MuiSvgIcon-root, .stepper-2 .MuiSvgIcon-root {
      color: ${theme.palette.text.disabled};
  }

  .button-right {
    text-align: right;
  }

  .button-right .MuiButton-root.Mui-disabled {
    background-color: ${theme.palette.action.disabled};
  }

  .MuiStepContent-root {
    border-left: 1px solid #bdbdbd;
  }


  @media screen and (max-width: 425px) {
    .button-right, .button-left {
      text-align: center;
      .MuiButton-root {
        width: 100%;
      }
    }
    .button-right.MuiGrid-root, .stepper.MuiGrid-root {
      padding-top: 20px !important;
      .MuiTypography-root {
        margin-top: 20px;
      }
    }
  }

      `
);


function AddCustomerAccount() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [allCompanyNames, setAllCompanyNames] = useState([]);
  const [allHostnames, setAllHostnames] = useState([]);
  const [websiteNameError, setWebsiteNameError] = useState(false);

  const clearError = () => {
    setHasError(false);
    setWebsiteNameError(false);
  }

  yup.addMethod(yup.string, 'getHostname', function () {
    return this.transform((website) => {
      let hostname;
      try {
        const url = new URL(website);
        hostname = last(split(url.hostname, 'www.'));
      }
      catch {
        hostname = last(split(website, 'www.'));
      }
      if(!hostname) {
        return ' ';
      }
      return hostname; 
    });
  });
      
  const validationSchema = yup.object({
    companyName: yup
      .string()
      .max(25, 'Name exceeds the character limit (maximum is 25 characters).')
      .required('This field is required')
      .notOneOf(allCompanyNames, 'Company name entered already exists.')
    ,
    companyWebsite: yup
      .string()
      .required('This field is required')
      .getHostname()
      .notOneOf(allHostnames, 'Website entered already exists. Try a different website.'),
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companyWebsite: '',
      tiers: 'standard'
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        let tiers = values.tiers;
        if (tiers === 'standard') {
          tiers = null;
        }
        else {
          tiers = split(tiers, ',');
        }

        const payload = omitBy({
          product: 'lens',
          status: 'prospect',
          name: values.companyName,
          website: values.companyWebsite,
          tiers
        }, isEmpty)

        const results = await createCarrierConfiguration(payload);

        if (results.statusCode === 400 || results.statusCode === 500) {
          if (results.message === "Duplicate claimKey") {
            setWebsiteNameError(true);
          }
          else {
            window.scrollTo(0, 0);
            setHasError(true);
          }
        }

        if (results?.status === 'prospect') {
          navigate(`/?accountSuccess=true&companyName=${values.companyName}`);
        }

        setIsLoading(false);
      }
      catch (e) {
        setIsLoading(false);
        setHasError(true);
      }
    }
  });

  useEffect(() => {
    const getConfigurations = async function () {
      const _allCarrierConfigurations = await getAllCarrierConfigurations();

      const _allCarrierNames = map(_allCarrierConfigurations, 'name');
      setAllCompanyNames(_allCarrierNames);

      const _allHostnames = map(_allCarrierConfigurations, 'claimKey');
      setAllHostnames(_allHostnames);
    };

    getConfigurations();
  }, []);

  return (
    <>
      <PageTitle title="Create Customer Account" />
      <PaperWrapper className={isLoading ? 'loading-wrapper' : ''} sx={{ py: 3 }}>
        {hasError ?
          <Alert sx={{ mx: 3 }} severity="error" onClick={clearError}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
          : ''}

        {isLoading ? <LoadingIcon /> :
          <FormikProvider value={formik}>
            <form className="form" onSubmit={formik.handleSubmit} action="/">
              <Grid container spacing={8} sx={{ p: 3, mt: 0 }}>
                <Grid item xs={12} sm={6} sx={{ pt: '0px !important' }}>

                  <Avatar variant="rounded" sx={{ margin: '0 auto 10px', backgroundColor: '#BDBDBD', borderRadius: '4px', width: '60px', height: '60px' }}><BusinessRounded className='company-icon' /></Avatar>
                  <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>{formik.values.companyName.length ? formik.values.companyName : '"Company Name"'}</Typography>

                  <TextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    value={formik.values.companyName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.companyName)}
                    helperText={formik.errors.companyName}
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    onClick={clearError}
                    fullWidth
                    id="companyWebsite"
                    name="companyWebsite"
                    label="Company Website"
                    value={formik.values.companyWebsite}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={(formik.touched.companyWebsite && Boolean(formik.errors.companyWebsite)) || websiteNameError}
                    helperText={formik.touched.companyWebsite ? formik.errors.companyWebsite : ''}
                    sx={{ mb: 3 }}
                  />
                  {websiteNameError && <FormHelperText sx={{ mt: -2, mb: 2 }} error>Website domain name entered already exists.</FormHelperText>}

                  <Typography color='text.secondary'>Assign LENS Tier</Typography>
                  <RadioGroup 
                    row 
                    id="tiers" 
                    name="tiers" 
                    value={formik.values.tiers} 
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value="standard" control={<Radio/>} label="Standard" />
                    <FormControlLabel value="lens-plus" control={<Radio/>} label="Plus" />
                    <FormControlLabel value="lens-verified" control={<Radio/>} label="Verified" />
                    <FormControlLabel value="lens-plus,lens-verified" control={<Radio/>} label="Plus + Verified" />
                  </RadioGroup>
                  <Box sx={{ border: '1px solid lightgrey', bgcolor: 'background.default' }} p={3} mt={1}>
                    <Typography variant='subtitle2' sx={{ mb: 1 }}>{tiers[formik.values.tiers].label}</Typography>
                    <Typography>{tiers[formik.values.tiers].description}</Typography>
                    <Typography>{accessText}</Typography>
                  </Box>
                </Grid>
                <Grid className="stepper" item xs={12} sm={6} sx={{ pt: '20px !important' }}>
                  <Typography variant="h6" sx={{ mt: '100px' }} >
                    Customer's Stage in LENS
                  </Typography>

                  <Stepper activeStep={0} orientation="vertical">
                    {steps.map((step, index) => (
                      <Step className={'stepper-' + index} active={true} key={step.label}>
                        <StepLabel>
                          <Typography variant='subtitle1'>{step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  <Typography mt={2}>After adding customer, go to the account's Users page to add users.</Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={8} sx={{ px: 3, pt: 2 }}>
                <Grid className="button-left" item xs={12} sm={6}>
                  <Button LinkComponent={Link} to="/" variant="outlined" color="primary">Cancel</Button>
                </Grid>
                <Grid className="button-right" item xs={12} sm={6}>
                  <Button type="submit" variant="contained" color="primary">Add Customer</Button>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        }
      </PaperWrapper>
    </>
  );
}

export default AddCustomerAccount;