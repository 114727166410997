import {useParams, useOutletContext} from 'react-router-dom';
import {Box, Grid, Skeleton, Typography} from '@mui/material';

import PageTitle from 'src/components/PageTitle';
import SideNavigation from 'src/components/SideNavigation';
import SourcesWidget from './SourcesWidget';
import TiersWidget from './TiersWidget';
import FrequenciesWidget from './FrequenciesWidget';
import ProfileWidget from './ProfileWidget';
import WidgetCard from './widgetCard';


const drawerWidth = 400;

function CustomerAccountDetails() {
  const {carrierId} = useParams();

  const {carrierConfigResponse} = useOutletContext();
  const {data: carrierConfig, isLoading: isCarrierConfigLoading, isError: isCarrierConfigError, refetch} = carrierConfigResponse || {};

  return (
    <>
      <PageTitle title={carrierConfig?.name} isLoading={isCarrierConfigLoading} isError={isCarrierConfigError}/>
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="details" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 4 }, fontWeight: 700 }}>
            DETAILS
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} sx={{mb: 2}}>
              <ProfileWidget
                carrierId={carrierId}
                companyName={carrierConfig?.name}
                website={carrierConfig?.website}
                contractBeginDate={carrierConfig?.contractBeginDate}
                contractEndDate={carrierConfig?.contractEndDate}
                isDataLoading={isCarrierConfigLoading}
                isApiError={isCarrierConfigError}
                refetch={refetch}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={12}>
                  <WidgetCard title='Date Created' isError={isCarrierConfigError}>
                    {isCarrierConfigLoading ? <Skeleton/> : <Typography>{carrierConfig?.dateAdded}</Typography>}
                  </WidgetCard>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <WidgetCard title='Account Status' isError={isCarrierConfigError}>
                    <Typography><strong>LENS Access:</strong> {isCarrierConfigLoading ? <Skeleton width='100px' sx={{display: 'inline-flex'}}/> : 'Activated'}</Typography>
                    {isCarrierConfigLoading ? <Skeleton/> : <Typography>{carrierConfig?.dateAdded}</Typography>}
                  </WidgetCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt: 3}}>
              <TiersWidget
                carrierId={carrierId}
                tiers={carrierConfig?.tiers}
                isDataLoading={isCarrierConfigLoading}
                isApiError={isCarrierConfigError}
                refetch={refetch}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <SourcesWidget 
                carrierId={carrierId}
                sources={carrierConfig?.sources}
                isDataLoading={isCarrierConfigLoading}
                isApiError={isCarrierConfigError}
                refetch={refetch}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
              <FrequenciesWidget 
                carrierId={carrierId}
                scanFrequencies={carrierConfig?.scanFrequencies}
                contractBeginDate={carrierConfig?.contractBeginDate}
                contractEndDate={carrierConfig?.contractEndDate}
                isDataLoading={isCarrierConfigLoading}
                isApiError={isCarrierConfigError}
                refetch={refetch}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CustomerAccountDetails;