import PropTypes from 'prop-types';

import { Chip } from '@mui/material';

function CategoryChips ({ categories }) {
  return (
    <>
      {categories.map((cat) => {
        return <Chip key={cat} size='small' sx={{ mr: '2px', mb: '2px', width: '45px' }} label={cat} />
      })}
    </>
  );
}

CategoryChips.propTypes = {
  categories: PropTypes.array
}

export default CategoryChips;
