import { getOrgAccessToken } from "src/utilities/okta";
import { OktaAuth } from '@okta/okta-auth-js';
import config from 'src/config/okta';

const internals = {};
const _oktaAuth = new OktaAuth(config.oidc);

export const downloadDocumentationFile = async function(filename) {
  const url = `/documentation/download`;
  
  return internals.get(url, {filename});
};

export const authorize = async function() {
  const url = `/authorize`;
  
  return internals.get(url);
};

export const getAllCarrierConfigurations = async function() {
  const url = `/carriers`;
  
  return internals.get(url);
};

export const getCarrierConfiguration = async function(carrierId) {
  const url = `/carriers/${carrierId}`;
  
  return internals.get(url);
};

export const updateDetailsCarrierConfiguration = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/details`;

  return internals.put(url, payload);
};

export const updateSourcesCarrierConfiguration = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/sources`;

  return internals.put(url, payload);
};

export const updateStatusCarrierConfiguration = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/status`;

  return internals.put(url, payload);
};

export const updateTiers = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/tiers`;

  return internals.put(url, payload);
};

export const updateTransferFrequencies = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/frequencies`;

  return internals.put(url, payload);
}

export const createCarrierConfiguration = async function(payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers`;
  
  return internals.putOkta(url, payload);
};

export const getCustomerFileDataLoads = async function(carrierId, filePrefix) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/carriers/${carrierId}/dataloads/customer-files/${filePrefix}`;
  
  return internals.get(url, {timezone});
};

export const getAllDataLoads = async function(carrierId, fileType) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/carriers/${carrierId}/dataloads/${fileType}`;
  
  return internals.get(url, {timezone});
};

export const getAllDataloadsHistory = async function(carrierId, fileType) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/carriers/${carrierId}/dataloads/all/${fileType}`;
  
  return internals.get(url, {timezone});
};

export const getDeathFileDataLoads = async function(carrierId, filePrefix) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/carriers/${carrierId}/dataloads/death-files/${filePrefix}`;
  
  return internals.get(url, {timezone});
};

export const getMatchesFileDataLoads = async function(carrierId, filePrefix) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/carriers/${carrierId}/dataloads/match-files/${filePrefix}`;
  
  return internals.get(url, {timezone});
};

export const getCustomerFileConfigurations = async function(carrierId) {
  const url = `/carriers/${carrierId}/files/customer-files`;
  
  return internals.get(url);
};

export const getDeathFileConfigurations = async function(carrierId) {
  const url = `/carriers/${carrierId}/files/death-files`;
  
  return internals.get(url);
};

export const getMatchFileConfigurations = async function(carrierId) {
  const url = `/carriers/${carrierId}/files/match-files`;
  
  return internals.get(url);
};

export const getHolidayCalendars = async function(carrierId) {
  const url = `/carriers/${carrierId}/holiday-calendars`;
  
  return internals.get(url);
}

export const getAuditConfigEntries = async function(carrierId, fileType) {
  const url = `/carriers/${carrierId}/files/${fileType}/audit-entries`;

  return internals.get(url);
};

export const deleteAllConfigurations = async function(carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/configurations`;
  
  return internals.delete(url);
};

export const getEmailAddresses = async function(carrierId) {
  const url = `/carriers/${carrierId}/emails`;
  
  return internals.get(url);
};

export const getErrorFileSignedUrl = async function(carrierId, fileType, dataLoadId) {
  const url = `/carriers/${carrierId}/dataloads/${fileType}/errors/download`;
  
  return internals.get(url, {dataLoadId});
};

export const createOktaUser = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/okta-users`;

  return internals.putOkta(url, payload);
}

export const getOktaUsers = async function(carrierId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/okta-users`;
  
  return internals.getOkta(url);
};

export const updateOktaUser = async function(carrierId, userId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/okta-users/${userId}`;
 
  return internals.putOkta(url, payload);
}

export const reactivateOktaUser = async function(carrierId, userId) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/okta-users/${userId}/reactivate`;

  return internals.putOkta(url, {});
}

export const provisionResources = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/setup`;

  return internals.put(url, payload);
};

export const getResourceProvisioningStatus = async function(carrierId) {
  const url = `/carriers/${carrierId}/setup`;

  return internals.get(url);
};

export const getEstimatedClaimsReports = async function(carrierId, limit, previous, next) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const url = `/carriers/${carrierId}/estimated-claims-reports`;

  return internals.get(url, {limit, previous, next, timezone});
};

export const getEstimatedClaimsReportDownloadUrl = async function(carrierId, matchJobId) {
  const url = `/carriers/${carrierId}/estimated-claims-reports/${matchJobId}/download`;
  
  return internals.get(url);
};

export const initiatePrescan = async function(carrierId, payload) {
  const url = `${process.env.REACT_APP_LENS_API}/carriers/${carrierId}/prescan-match-jobs`;

  return internals.post(url, payload);
};

internals.get = async function(route, query={}) {
  const accessToken = _oktaAuth.getAccessToken();
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(query)) {
    if (value) {
      if (Array.isArray(value)) {
        for (const arrayValue of value ) {
          queryParams.append(key, arrayValue);
        }
      } else {
        queryParams.append(key, value);
      }
    }
  }

  const queryString = queryParams.toString();

  const url = `${process.env.REACT_APP_LENS_API}${route}?${queryString}`;

  return internals.fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

internals.delete = async function(url) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

internals.getOkta = async function(url) {
  const accessToken = _oktaAuth.getAccessToken();
  const orgAccessToken = getOrgAccessToken(_oktaAuth);

  return internals.fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Okta-Auth': `Bearer ${orgAccessToken}`
    },
  });
};

internals.put = async function(url, payload) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload)
  });
};

internals.post = async function(url, payload) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload)
  });
}

internals.putOkta = async function(url, payload) {
  const accessToken = _oktaAuth.getAccessToken();
  const orgAccessToken = getOrgAccessToken(_oktaAuth);

  return internals.fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Okta-Auth': `Bearer ${orgAccessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

internals.fetch = async function(url, payload) {
  const result = await fetch(url, payload);

  if(result.ok) { // we have success, return the json payload
    if(result.status === 204){
      return {"status": result.status, "statusText": result.statusText};
    }
    return result.json();
  }

  const _json = await result.json();

  const {statusCode} = _json; // when the server throws a Joi error, then this is available and we want the body

  if(statusCode > 200) {
    return _json;
  }

  return result;
};