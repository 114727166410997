import PropTypes from 'prop-types';
import {Typography, Grid, Divider} from "@mui/material";

import CategoryChips from 'src/components/CategoryChips';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';


function ExpandedContent({row}) {
  return (
    <>
      <Typography variant='subtitle1'>Below are the sources and match categories utilized for matching to build this report.</Typography>
      <Grid container >
        <Grid item xs={3} mt={1}>
          <Typography variant='subtitle1'>Sources:</Typography>
          {row?.sources?.map((source) => <Typography key={source}>{sourceDisplayMap[source]}</Typography>)}
        </Grid>
        <Grid item>
          <Divider sx={{height: '100%', width: '2px', mr: 4}} orientation='vertical' variant='middle'/>
        </Grid>
        <Grid item xs={8} mt={1}>
          <Typography variant='subtitle1'>Match Categories:</Typography>
          <CategoryChips categories={row?.categories}/>
        </Grid>
      </Grid>
      <Typography mt={2} variant='body2'><strong>Requested by:</strong> {row?.requestedBy}</Typography>
    </>
  );
}

ExpandedContent.propTypes = {
  row: PropTypes.object
};

export default ExpandedContent;
