import {useState} from 'react';
import {useParams, useOutletContext} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Box, Grid, Paper, Typography, Alert} from '@mui/material';

import {getOktaUsers} from 'src/api';
import TableWithActionButton from 'src/components/TableWithActionButton';
import SideNavigation from 'src/components/SideNavigation';
import PageTitle from 'src/components/PageTitle';
import getUserColumns from './columns';
import CustomerAccountEditUser from './Customer Account Edit User';
import CustomerAccountAddUser from './Customer Account Add User';
import SkeletonRows from 'src/components/SkeletonRows';


const drawerWidth = 400;

function CustomerAccountUsers() {
  const {carrierId} = useParams();

  const {carrierConfigResponse} = useOutletContext();
  const {data: carrierConfig, isLoading: carrierConfigLoading, isError: carrierConfigError} = carrierConfigResponse || {};

  const [managingUsers, setManagingUsers] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [addUserFirstName, setAddUserFirstName] = useState('');
  const [addUserLastName, setAddUserLastName] = useState('');
  
  function callManageUsers(row) {
    setCurrentUser(row);
    setManagingUsers(true);
  }

  async function showAddUserSuccess(firstName, lastName) {
    setAddUserFirstName(firstName);
    setAddUserLastName(lastName);
    setAddUserSuccess(true);
    setTimeout(() => setAddUserSuccess(false), 3000);
  }

  const usersQuery = useQuery(['getUsers', carrierId], () => getOktaUsers(carrierId), {retry: false});
  const users = usersQuery?.data;

  function backToUsers() {
    setManagingUsers(false);
    setCreatingUser(false);
  }

  return (
    <>
      <PageTitle title={carrierConfig?.name} isLoading={carrierConfigLoading} isError={carrierConfigError}/>
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="users" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          {(!managingUsers && !creatingUser) &&
            <>
              <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 3 }, fontWeight: 700 }}>
                USERS
              </Typography>
              <Grid container spacing={3}>
                <Alert severity="success" className={addUserSuccess ? 'show' : 'hide'} sx={{ mx: 2, mt: 2 }}>{`${addUserFirstName} ${addUserLastName} created successfully!`}</Alert>
                <Grid item xs={12}>
                  <Paper sx={{ mb: 2, mt: 1 }}>
                    <Grid container spacing={2} sx={{ pl: 2, pt: 0 }}>
                      <TableWithActionButton
                        skeleton={<SkeletonRows widths={['10%', '15%', '15%', '20%', '20%', '10%', '10%']}/>}
                        customActionButton={true}
                        customButtonText="Create User"
                        customButtonAction={() => setCreatingUser(true)}
                        hideTable={users?.length === 0}
                        message={'There are no users to display.'}
                        isDataTableLoading={usersQuery?.isLoading}
                        isError={usersQuery?.isError}
                        initialState={{sorting: {sortModel: [{ field: 'dateCreated', sort: 'desc' }],},}}
                        rows={users || {}}
                        columns={getUserColumns(callManageUsers)}
                        rowHeight={60}
                        tableHeight={485}
                        title="Manage Users"
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          }
          {managingUsers && <CustomerAccountEditUser currentUser={currentUser} backToUsers={backToUsers} users={users} carrierId={carrierId} reload={usersQuery?.refetch} />}

          {creatingUser && <CustomerAccountAddUser backToUsers={backToUsers} users={users} carrierId={carrierId} reload={usersQuery?.refetch} showAddUserSuccess={showAddUserSuccess}/>}
        </Box>
      </Box>
    </>
  );
}

export default CustomerAccountUsers;