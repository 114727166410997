import PropTypes from 'prop-types';
import {Typography, Skeleton} from '@mui/material';


function DisplayContractDates({beginDate, endDate, isLoading}) {
  const contractDateFormat = new Intl.DateTimeFormat('en-US', { year: "numeric", month: "long", day: "numeric" });
  const formattedDates = beginDate && endDate ? `${contractDateFormat.format(Date.parse(beginDate))} - ${contractDateFormat.format(Date.parse(endDate))}` : 'Add dates before moving account to Live stage.';

  return (
    <Typography variant="body1">
      <strong>Contract Dates: </strong>
      {isLoading ? 
        <Skeleton width='300px' sx={{display: 'inline-flex'}}/>
        :
        formattedDates
      }
    </Typography>
  );
}

DisplayContractDates.propTypes = {
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  isLoading: PropTypes.bool
};

export default DisplayContractDates;