import PropTypes from 'prop-types';
import {Grid, styled} from '@mui/material';

import TableWithActionButton from 'src/components/TableWithActionButton';
import { ReactComponent as EmptyStateSvg } from 'src/images/EmptyStateDataSavedOn.svg'
import EmptyState from 'src/components/EmptyState/index.js';
import CollapsibleTable from 'src/components/CollapsibleTable';
import SkeletonRows from 'src/components/SkeletonRows';
import fileColumns from './DataGridColumns/fileColumns';
import matchesColumns from './DataGridColumns/matchesColumns';


const GridWrapper = styled(Grid)(
  () => `

@media screen and (max-width: 768px) {
  .MuiDataGrid-cell {
  min-width: 150px !important;
  }
  .MuiDataGrid-columnHeader {
    min-width: 150px !important;
  }
}
`
)

function DataLoadsStatus({carrierId, isNewUser, isDataTableLoading, isError, dataLoads, dataType, filterApplied}) {

  return (
    <GridWrapper>
      <div className={isNewUser === 'true' ? 'hide' : 'show'}>
        { dataType === 'deathFile' &&
          <TableWithActionButton
            skeleton={<SkeletonRows widths={['18%', '12%', '12%', '12%', '10%', '15%', '10%', '12%']}/>}
            isDataTableLoading={isDataTableLoading}
            isError={isError}
            hideTable={!isError && dataLoads?.length === 0 && !isDataTableLoading}
            message="No file activity to display."
            rows={dataLoads}
            columns={fileColumns(carrierId)}
            rowHeight={100}
            tableHeight={isDataTableLoading ? 400 : 700} />
        }
        { dataType === 'customerFile' &&
          <CollapsibleTable
            columns={fileColumns(carrierId)}
            data={dataLoads}
            message="No file activity to display."
            usePagination
            isLoading={isDataTableLoading} 
            isError={isError}/>
        }
        { dataType === 'matches' &&
          <CollapsibleTable
            columns={matchesColumns}
            data={dataLoads}
            message={filterApplied ? "No file activity found for the selected criteria." : "No file activity to display."}
            usePagination
            isLoading={isDataTableLoading}
            isError={isError}/>
        }
      </div>
      <div className={isNewUser === 'true' ? 'show' : 'hide'}>
        <EmptyState icon={<EmptyStateSvg />} />
      </div>
    </GridWrapper>
  );
}

DataLoadsStatus.propTypes = {
  carrierId: PropTypes.string,
  dataType: PropTypes.string.isRequired,
  isNewUser: PropTypes.string,
  isDataTableLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  dataLoads: PropTypes.array,
  filterApplied: PropTypes.bool
};

export default DataLoadsStatus;
