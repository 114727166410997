import {useState} from 'react';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';

import {getEstimatedClaimsReportDownloadUrl} from 'src/api';
import FileDownloadIcons from 'src/components/FileDownloadIcons';


function FileDownload({carrierId, matchJobId, setIsDownloadError}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      setIsDownloadError(false);
      setShowErrorIcon(false);

      const {url, filename} = await getEstimatedClaimsReportDownloadUrl(carrierId, matchJobId);
      const result = await fetch(url);
      const data = await result.blob();
      fileDownload(data, filename);

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      setIsDownloadError(true);
      setShowErrorIcon(true);
    }
  };

  return (
    <FileDownloadIcons isDownloading={isDownloading} handleDownload={handleDownload} isDownloadError={showErrorIcon}/>
  )
}

FileDownload.propTypes = {
  carrierId: PropTypes.string, 
  matchJobId: PropTypes.string,
  setIsDownloadError: PropTypes.func
};

export default FileDownload;
