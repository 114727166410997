import {Alert} from '@mui/material';
import {CheckCircleOutline} from '@mui/icons-material';


function SuccessAlert() {
  return (
      <Alert
        iconMapping={{success: <CheckCircleOutline fontSize="inherit" />}}
        className="completed-alert"
        severity="success"
      >
        Claims report request submitted successfully.
      </Alert>
  );
}

export default SuccessAlert;
