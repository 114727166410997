import {useState} from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, Typography, Skeleton} from '@mui/material';
import {times} from 'lodash-es';

import ExpandableRow from './expandableRow';
import LoadError from 'src/components/LoadError';


function ExpandableTable({queryResponse, limit, setQueryParams, columns}) {
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setQueryParams((params) => {
      return {
        ...params,
        next: (newPage > page) && queryResponse?.data?.pagination?.next,
        previous: (newPage < page) && queryResponse?.data?.pagination?.previous
      }
    });
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setQueryParams((params) => {
      return {
        limit: event.target.value
      }
    });
  }



  const displayedRowsLabel = ({from}) => queryResponse?.isLoading ? '0-0' : `${from}-${from + queryResponse?.data?.result?.length - 1}`

  if (queryResponse?.isError) {
    return <LoadError height='300px'/>
  }

  if (!queryResponse?.isLoading && queryResponse?.data?.result?.length === 0) {
    return <Typography p={3}>No reports to display.</Typography>
  }

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel disabled={true}>Action</TableSortLabel>
            </TableCell>
            {columns?.map((column) => 
              <TableCell align='left' key={column?.field}>
                <TableSortLabel direction={column?.sortDirection} disabled={true} active={Boolean(column?.sortDirection)}>{column?.headerName}</TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 500 }}>
          {queryResponse?.isLoading ?
            times(5, () => <TableRow>{times(columns?.length + 1, () => <TableCell><Skeleton/></TableCell>)}</TableRow>)
            :
            queryResponse?.data?.result?.map((row) => <ExpandableRow key={row?.reportName} row={row} columns={columns} expandable={row?.status === 'completed' || row?.status === 'failed'}/>)
          }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={displayedRowsLabel}
        slotProps={{actions: {nextButton: {disabled: !queryResponse?.data?.pagination?.next}}}}
      />
    </TableContainer>
  );
}

ExpandableTable.propTypes = {
  queryResponse: PropTypes.object,
  limit: PropTypes.number,
  setQueryParams: PropTypes.func,
  columns: PropTypes.array
};

export default ExpandableTable;
