import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Typography, Skeleton, Tooltip} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';

import {updateSourcesCarrierConfiguration} from 'src/api';
import WidgetCard from '../widgetCard';
import {toolTipText, sourcesMap} from './constants';
import SourcesForm from './sourcesForm';


const validationSchema = yup.object().shape({
  sources: yup
    .array()
    .min(1, 'Field is required')
})

function SourcesWidget({carrierId, sources, isDataLoading, isApiError, refetch}) {
  const formik = useFormik({
    initialValues: {
      sources: sources,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      formik?.setStatus({error: false});
      try {
        const payload = { 'sources': values.sources };
        const result = await updateSourcesCarrierConfiguration(carrierId, payload);
        
        if (result.statusCode === 400 || result.statusCode === 500) {
          formik?.setStatus({error: true});
        }
        else {
          await refetch();
        }
      } catch (e) {
        formik?.setStatus({error: true});
      }
    }
  });

  return (
    <WidgetCard title='Contracted Sources' formik={formik} editingForm={<SourcesForm/>} isLoading={isDataLoading} isError={isApiError}>
      {isDataLoading ? <Typography><Skeleton/></Typography> :
        <>
          {(!sources || sources?.length === 0) &&
            <Typography variant="body1">
              View sources here after account has moved out of the Prospect stage in LENS.
            </Typography>
          }
          {sources?.length !== 0 &&
            <>
              <Typography variant="subtitle1">
                Sources for this customer account:
                <Tooltip title={toolTipText} arrow placement="right" sx={{ ml: '10px', mb: '-3px' }}><InfoRounded color='primary' fontSize='small' ></InfoRounded></Tooltip>
              </Typography>
              {sources?.sort()?.map((item) => (
                <Typography key={item} variant='body1' sx={{ textTransform: sourcesMap[item] || 'capitalize' }}>{sourcesMap[item] || item}</Typography>
              ))}
            </>
          }
        </>
      }
    </WidgetCard>
  );
}

SourcesWidget.propTypes = {
  carrierId: PropTypes.string,
  sources: PropTypes.array,
  isDataLoading: PropTypes.bool,
  isApiError: PropTypes.bool,
  refetch: PropTypes.func
};

export default SourcesWidget;
