import PropTypes from 'prop-types';
import {Typography, Skeleton} from '@mui/material';
import {useFormik} from 'formik';
import {join, split} from 'lodash-es';

import {updateTiers} from 'src/api';
import WidgetCard from '../widgetCard';
import {tiersMap, accessText} from './constants';
import TiersForm from './tiersForm';


function TiersWidget({carrierId, tiers, isDataLoading, isApiError, refetch}) {
  const combinedTiers = join(tiers);

  const formik = useFormik({
    initialValues: {
      tiers: combinedTiers
    },
    enableReinitialize: true,
    onSubmit: async () => {
      formik?.setStatus({error: false});
      try {
        let tiers = split(formik.values.tiers, ",");
        tiers = tiers[0] === "" ? [] : tiers; 
        const result = await updateTiers(carrierId, {tiers});
        if (result.statusCode === 400 || result.statusCode === 500) {
          formik?.setStatus({error: true});
        }
        else {
          await refetch();
        }
      } catch (error) {
        formik?.setStatus({error: true});
      }
    }
  });

  return (
    <WidgetCard title='LENS Tier' formik={formik} editingForm={<TiersForm/>} isLoading={isDataLoading} isError={isApiError}>
      <Typography variant='subtitle1'>{isDataLoading ? <Skeleton/> : tiersMap?.[combinedTiers]?.label}</Typography>
      <Typography>{isDataLoading ? <Skeleton/> : tiersMap?.[combinedTiers]?.description}</Typography>
      {!isDataLoading && <Typography>{accessText}</Typography>}
    </WidgetCard>
  );
}

TiersWidget.propTypes = {
  carrierId: PropTypes.string,
  tiers: PropTypes.array,
  isDataLoading: PropTypes.bool,
  isApiError: PropTypes.bool,
  refetch: PropTypes.func
}

export default TiersWidget;