import {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Grid, Box, Divider, styled, Typography, Button} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';

import {getHolidayCalendars} from 'src/api';
import Actions from '../Actions';
import SkeletonTable from './skeletonTable';


const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};

    & .fc-license-message {
      display: none;
    }
    .fc {

      .fc-col-header-cell {
        background: ${theme.colors.alpha.black[5]};
        font-weight: bold;
      }

      .fc-scrollgrid {
        border: 2px solid ${theme.colors.alpha.black[10]};
        border-right-width: 1px;
        border-bottom-width: 1px;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-theme-standard td, .fc-theme-standard th,
      .fc-col-header-cell {
        border: 1px solid ${theme.colors.alpha.black[10]};
      }

      .fc-event {
        padding: ${theme.spacing(0.1)} ${theme.spacing(0.3)};
      }

      a.fc-event {
        background-color: ${theme.palette.primary.main} !important;
        text-align: center;
        border-radius: 8px;
      }

      .fc-list-day-side-text {
        font-weight: normal;
        color: ${theme.colors.alpha.black[70]};
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: ${theme.colors.alpha.black[10]};
      }

      .fc-daygrid-dot-event:hover, 
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: #eeeee !important;
      }

      .fc-cell-shaded, 
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[3]} !important;
        color: ${theme.colors.alpha.black[100]} !important;
        height: 60px;
        padding-top: 18px;
      }

      &.fc-theme-standard td, 
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border-color: ${theme.colors.alpha.black[30]};
      }
      .fc-event-time, .fc-list-event-time, .fc-list-event-dot {
        display: none;
      }
      td.fc-list-event-graphic {
        display: none;
      }
      .fc-list-event-title {
        height: 60px;
        padding-top: 18px;
    }
    .fc-list-event-title {
      cursor: default;
      background: none !important
    }
`
);

function CalendarDrawer({ closeCalendarDrawer, holidayCalendarId }) {
  const {carrierId} = useParams();
  const calendarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  const [genericError, setGenericError] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);


  const handleDateToday = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.today();
      setDate(calApi.getDate());
    }
  };

  const handleDatePrev = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.prev();
      setDate(calApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.next();
      setDate(calApi.getDate());
    }
  };

  const transformEvents = (_rawEvents) => {
    if (_rawEvents) {
      const result = Object.keys(_rawEvents?.[0].calendar).map((key, index) => [
        {
          id: index,
          start: new Date(key),
          title: _rawEvents[0].calendar[key]
        }]);
      return result.flat(1);
    }
  }

  const getEvents = (info, successCallback) => {
    successCallback(events);
  };

  const retry = () => {
    setGenericError(false);
    setIsLoading(true);
    setReloadCounter(reloadCounter+1);
  }

  useEffect(() => {
    let _events = [];

    const getCalendar = async function (carrierId) {
      let results = null;
      let holidayCalendar=[];

      try{
        results = await getHolidayCalendars(carrierId);
      }
      catch(error){
      }

      if(results) {
        holidayCalendar = results?.filter((holidayCalendar) => {return holidayCalendar.id === holidayCalendarId});
      }

      if (results?.length > 0 && holidayCalendar?.length === 0) {
        holidayCalendar = results;
      }

      if (holidayCalendar[0]?.calendar) {
        _events = transformEvents(holidayCalendar);
        if (events.length === 0) {
          setEvents(_events);
        }
      }
      else {
        setGenericError(true);
      }
    }

    getCalendar(carrierId).finally(() => setIsLoading(false))
  }, [events, carrierId, holidayCalendarId, reloadCounter]);

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} p={3}>
          <Typography variant='h6'><b>Company Holidays</b></Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          {genericError ? 
            <>                
              <Grid item sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography sx={{ p:5, pt:20 }}>We're having trouble loading the content, please try again.</Typography>
              </Grid>
              <Grid item pb={5} sx={{display: 'flex', justifyContent: 'center'}}>
                <Button variant='contained' onClick={retry}>Retry</Button>
              </Grid>
            </>
            : 
            <>
              <Box p={3} pb={1}>
                <Actions
                  date={date}
                  onNext={handleDateNext}
                  onPrevious={handleDatePrev}
                  onToday={handleDateToday}
                  isLoading={isLoading}
                />
              </Box>
              <FullCalendarWrapper>
                {isLoading ? <SkeletonTable/> :
                  <FullCalendar
                    allDayMaintainDuration
                    initialDate={date}
                    initialView="listYear"
                    eventDisplay="block"
                    dayMaxEventRows={4}
                    events={(info, successCallback) => getEvents(info, successCallback)}
                    headerToolbar={false}
                    height='auto'
                    ref={calendarRef}
                    rerenderDelay={10}
                    stickyHeaderDates={false}
                    weekends
                    plugins={[
                      listPlugin
                    ]}
                  />
                }
              </FullCalendarWrapper>
            </>
          }
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" p={3}>
        <Grid item>
          <Button onClick={closeCalendarDrawer} variant='contained'>Close</Button>
        </Grid>
      </Grid>
    </>
  );
}

CalendarDrawer.propTypes = {
  holidayCalendarId: PropTypes.string,
  closeCalendarDrawer: PropTypes.func
}

export default CalendarDrawer;