import PropTypes from 'prop-types';
import {IconButton, styled, Tooltip} from '@mui/material';
import {FileDownloadRounded, DownloadingRounded, ErrorOutline} from '@mui/icons-material';


const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(() => (`
    color: #D9000B;
    background-color: #fbe5e7;
    width: 110px;
    border-bottom: 2px solid #D9000B;
    .MuiTooltip-arrow { 
      color: #fbe5e7;
    }
`));

function FileDownloadIcons({isDownloading, handleDownload, isDownloadError}) {
  return (
    isDownloading ?
      <DownloadingRounded color='disabled'/>
      :
      <>
        <Tooltip title='Download Report' arrow>
          <IconButton onClick={handleDownload} size='small' color='primary'>
            <FileDownloadRounded color='primary'/>
          </IconButton>
        </Tooltip>
        {isDownloadError &&
          <IconButton color='error' disableTouchRipple size='small' sx={{ml: 2, cursor: 'default'}}>
            <CustomTooltip title='Error retrieving file, try again.'>
              <ErrorOutline color='error' sx={{textAlign: 'right'}}/>
            </CustomTooltip>
          </IconButton>
        }
      </>
  );
}

FileDownloadIcons.propTypes = {
  isDownloading: PropTypes.bool,
  handleDownload: PropTypes.func,
  isDownloadError: PropTypes.bool
}

export default FileDownloadIcons;
