import { Field } from 'formik';
import PropTypes from 'prop-types';
import {toPairs, sortBy} from 'lodash-es';

import { FormControlLabel, FormControl, Checkbox, FormHelperText, Typography } from '@mui/material';

import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';

const sourceOptions = sortBy(toPairs(sourceDisplayMap), (source) => source[1]);

function SelectSources({ formik }) {
  const validationTriggered = Boolean(formik.touched.sources && formik.errors.sources);

  return (
    <FormControl sx={{ pb: 2 }} component="fieldset" >
      <FormHelperText error={validationTriggered}>
        <Typography variant="body2" ml={-2}>Minimum of one source required.{validationTriggered && '*'}</Typography>
      </FormHelperText>
      {sourceOptions.map((source) => 
        <Field 
          sx={{ pl: 1 }}
          as={FormControlLabel}
          key={source[0]}
          name="sources"
          value={source[0]}
          checked={formik?.values?.sources.includes(source[0])}
          control={<Checkbox onChange={(e) => {formik.setFieldTouched('sources'); formik.handleChange(e);}} />}
          label={source[1]}/>
      )}
      <FormHelperText sx={{ visibility: validationTriggered ? 'visible' : 'hidden' }} error={true}>
        <Typography fontSize="13px" ml={-2}>*At least one source selection is required.</Typography>
      </FormHelperText>
    </FormControl>
  );
}

SelectSources.propTypes = {
  formik: PropTypes.object.isRequired
};

export default SelectSources;