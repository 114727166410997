import {useFormikContext} from 'formik';
import {Grid, Typography, TextField, FormHelperText, Avatar} from '@mui/material';
import {InfoOutlined, BusinessRounded} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs";

import FieldValidationMessage from 'src/components/FieldValidationMessage';


function ProfileForm() {
  const {values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldTouched} = useFormikContext();

  return (
    <Grid container spacing={3} sx={{ p: 3 }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Avatar variant="rounded" sx={{ margin: '0 auto 15px', backgroundColor: '#bdbdbd', width: '60px', height: '60px', borderRadius: '6px' }}>
          <BusinessRounded sx={{ color: 'background.paper', fontSize: '30px' }} />
        </Avatar>
        <Typography variant="h5">
          {values?.companyName}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <TextField
          fullWidth
          id="companyName"
          name="companyName"
          label="Company Name"
          className="companyName"
          value={values?.companyName}
          onBlur={handleBlur}
          onChange={handleChange}
          error={Boolean(errors?.companyName)}
        />
        <FieldValidationMessage fieldTouched={touched?.companyName} validationMessage={errors?.companyName} />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <TextField
          fullWidth
          id="companyWebsite"
          name="companyWebsite"
          label="Company Website"
          className="companyWebsite"
          value={values?.companyWebsite}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.companyWebsite && Boolean(errors.companyWebsite)}
        />
        <FieldValidationMessage fieldTouched={touched?.companyWebsite} validationMessage={errors?.companyWebsite} />
      </Grid>
      <Grid item xs={12}>
        <Typography color="text.secondary" mb={2}>Contract Dates (Optional)</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={values?.contractStartDate ? dayjs(values?.contractStartDate) : null}
            onChange={(date) => setFieldValue('contractStartDate', date?.isValid() ? date.format('MM/DD/YYYY') : date)}
            onAccept={() => setFieldTouched('contractStartDate', true)}
            sx={{width: '100%'}}
            slotProps={{
              textField: {
                error: errors?.contractStartDate && touched?.contractStartDate,
              }
            }}
          />
          {errors?.contractStartDate && touched?.contractStartDate && <FormHelperText error sx={{ml: -1, fontWeight: 400}}><InfoOutlined sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />{errors?.contractStartDate}</FormHelperText>}
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker 
            label="End Date"
            value={values?.contractEndDate ? dayjs(values?.contractEndDate) : null}
            onChange={(date) => setFieldValue('contractEndDate', date?.isValid() ? date.format('MM/DD/YYYY') : date)}
            onAccept={() => setFieldTouched('contractEndDate', true)}
            sx={{width: '100%'}}
            slotProps={{
              textField: {
                error: errors?.contractEndDate && touched?.contractEndDate,
              },
            }}
          />
          {errors?.contractEndDate && touched?.contractEndDate && <FormHelperText error sx={{ml: -1, fontWeight: 400}}><InfoOutlined sx={{ width: '17px', display: 'inline-block', float: 'left', mt: '-2px', mr: '3px' }} />{errors?.contractEndDate}</FormHelperText>}
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export default ProfileForm;
