import {Typography, Skeleton, Divider, Box} from "@mui/material";


function StepperSkeleton() {
  return (
    <Box mt={2} mr={2} mb={2}>
      <Typography variant='subtitle1'><Skeleton width='216px'/></Typography>
      <Box display='inline-flex' width='100%' mt={1} ml={1}>
        <Divider orientation='vertical' variant='middle' sx={{height: '16px', width: '2px', marginTop: 0.5, marginRight: 2}}/>
        <Skeleton height='25px' width='100%'/>
      </Box>
      <Typography variant='subtitle1'><Skeleton width='216px'/></Typography>
      <Box display='inline-flex' width='100%' mt={1} ml={1}>
        <Divider orientation='vertical' variant='middle' sx={{height: '16px', width: '2px', marginTop: 0.5, marginRight: 2}}/>
        <Skeleton height='25px' width='100%'/>
      </Box>
      <Typography variant='subtitle1'><Skeleton width='216px'/></Typography>
      <Box display='inline-flex' width='100%' mt={1} ml={1}>
        <Divider orientation='vertical' variant='middle' sx={{height: '16px', width: '2px', marginTop: 0.5, marginRight: 2}}/>
        <Skeleton height='25px' width='100%'/>
      </Box>
    </Box>
  );
}

export default StepperSkeleton;
