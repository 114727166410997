import {Avatar} from '@mui/material';
import {BusinessRounded} from '@mui/icons-material';


function BusinessAvatar() {
  return (
    <Avatar variant="rounded" sx={{ margin: '0 auto 15px', backgroundColor: '#bdbdbd', width: '60px', height: '60px', borderRadius: '6px' }}>
      <BusinessRounded sx={{ color: 'background.paper', fontSize: '30px' }} />
    </Avatar>
  );
}

export default BusinessAvatar;